import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ContactComponent } from './contact/contact.component';
import { ProductsComponent } from './products/products.component';
import { PaynowComponent } from './paynow/paynow.component';
import { MerchantpayComponent } from './merchantpay/merchantpay.component';
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from './examples/landing/landing.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        ContactComponent,
        ProductsComponent,
        PaynowComponent,
        MerchantpayComponent,
        FooterComponent,
        LandingComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        ComponentsModule,
        ExamplesModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
