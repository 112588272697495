import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchantpay',
  templateUrl: './merchantpay.component.html',
  styleUrls: ['./merchantpay.component.css']
})
export class MerchantpayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
